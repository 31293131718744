
import { mapState, mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
        ...mapGetters({
            jackpot: 'cms/jackpot',
            jackpotVal: 'cms/jackpotVal',
        }),
        jackpotSlide() {
            const chunkSize = 4
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.jackpot?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.jackpot?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-jackpot-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth - 40
            // JackpotContent size is 300x160, so I have an formula to calculate the size:
            /**
             * 4 image view (450 x 2) x (250 x 2) + 10
             * 2 image view (450 x 2) x 250
             */
            const sliderContainerWidth = 300 * 2
            let sliderContainerHeight = 160
            if (this.jackpotSlide?.length === 0) {
                sliderContainerHeight = 160
            } else if (
                this.jackpotSlide?.length === 1 &&
                [1, 2].includes(this.jackpotSlide[0]?.length)
            ) {
                sliderContainerHeight = 160
            } else {
                sliderContainerHeight = 160 * 2 + 10
            }
            const height =
                (screenWidth * sliderContainerHeight) / sliderContainerWidth
            this.imageHeight = height
        },
    },
}
