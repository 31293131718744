
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            promotion: 'cms/promotion',
        }),
        promotionContent() {
            const chunkSize = 6
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.promotion?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(this.promotion?.items).slice(
                    i,
                    i + chunkSize
                )
                result.push(chunk)
            }

            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-promotion-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth - 40
            // Promotion Content size is 500x300, so I have an formula to calculate the size:
            /**
             * 6 image view (500 x 3 )  x ((300 x2) + 10)
             * 3 image view (500 x 3 ) x 300
             */
            const sliderContainerWidth = 500 * 3
            let sliderContainerHeight = 300
            if (this.promotionContent?.length === 0) {
                sliderContainerHeight = 300
            } else if (
                this.promotionContent?.length === 1 &&
                [1, 2, 3].includes(this.promotionContent[0]?.length)
            ) {
                sliderContainerHeight = 300
            } else {
                sliderContainerHeight = 500 * 2 + 10
            }
            const height =
                (screenWidth * sliderContainerHeight) / sliderContainerWidth
            this.imageHeight = height
        },
    },
}
