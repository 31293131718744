
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent2: 'cms/featuredContent2',
        }),
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-activities-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth
            // FeaturedContent2 size is 600x400, so I have an formula to calculate the size:
            const height = (screenWidth * 400) / 600
            this.imageHeight = height
        },
    },
}
