
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            imageHeight: 0,
        }
    },
    computed: {
        ...mapGetters({
            featuredContent3: 'cms/featuredContent3',
        }),
        featuredContent() {
            const chunkSize = 4
            const result = []
            for (
                let i = 0;
                i < this.getVisibleItems(this.featuredContent3?.items).length;
                i += chunkSize
            ) {
                const chunk = this.getVisibleItems(
                    this.featuredContent3?.items
                ).slice(i, i + chunkSize)
                result.push(chunk)
            }
            return result
        },
    },
    methods: {
        onNext() {
            window.document.getElementById('csl-feature-article-next').click()
        },
        onResize() {
            const screenWidth = window.innerWidth - 40
            // FeaturedContent3 size is 450x250, so I have an formula to calculate the size:
            /**
             * 4 image view (450 x 2) x ((250 x 2) + 10)
             * 2 image view (450 x 2) x 250
             */
            const sliderContainerWidth = 450 * 2
            let sliderContainerHeight = 250
            if (this.featuredContent?.length === 0) {
                sliderContainerHeight = 250
            } else if (
                this.featuredContent?.length === 1 &&
                [1, 2].includes(this.featuredContent[0]?.length)
            ) {
                sliderContainerHeight = 250
            } else {
                sliderContainerHeight = 250 * 2 + 20
            }
            const height =
                (screenWidth * sliderContainerHeight) / sliderContainerWidth
            this.imageHeight = height
        },
    },
}
